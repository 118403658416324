.main_contact_content.mobile {
  width: 100vw;
  padding: 50px 80px 40px 80px;
  background-color: #31343E;

  .contact_section {
    text-align: center;
  }

  .icon_container {
    margin: auto;
    width: 61%;
  }

}

.contact_section {
  flex: 1;
  padding: 100px 0 0 0;
  color: #B1B6BE;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  min-width: 200px;
}

#section1 p {
  color: #B1B6BE;
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
}

.contact_section h3 {
  color: #B1B6BE;
  font-size: 17px;
  font-weight: 700;
}

.contact_logo-cont {
  width: 110px;
  height: 45px;
}

#section2,
#section3 {
  display: flex;
  flex-direction: column;
}

#section2 div,
#section3 div {
  margin-bottom: 15px;
}

#section2 p,
#section3 p {
  margin-bottom: -20px;
  color: #B1B6BE;
  font-size: 14px;
  font-weight: 500;
}

#section2,
#section3,
#section4 {
  padding: 70px 0 20px 30px;
  margin-left: 30px;
}

.icon_container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.icon_sub_container {
  display: flex;
  border: 1px solid #575B67;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  align-items: center;
  justify-content: center;

}

.main_contact_content {
  width: 100vw;

  padding: 50px 80px 40px 80px;
  background-color: #31343E;



  .contact_section {
    flex: 1;
    padding: 100px 0 0 0;
    color: #B1B6BE;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    min-width: 200px
  }

  #section1 p {
    color: #B1B6BE;
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .contact_section h3 {
    color: #B1B6BE;
    font-size: 17px;
    font-weight: 700;
  }

  .contact_logo-cont {
    width: 110px;
    height: 45px;
  }

  #section2,
  #section3 {
    display: flex;
    flex-direction: column;
  }

  #section2 div,
  #section3 div {
    margin-bottom: 15px;
  }

  #section2 p,
  #section3 p {
    margin-bottom: -20px;
    color: #B1B6BE;
    font-size: 14px;
    font-weight: 500;
  }

  #section2,
  #section3,
  #section4 {
    padding: 70px 0 20px 30px;
    margin-left: 30px;
  }

  .icon_container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }

  .icon_sub_container {
    display: flex;
    border: 1px solid #575B67;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
  }

  .line {

    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #B1B6BE;
      ;
      margin: 1em 0;
      padding: 0;
    }

    p {
      color: #B1B6BE;
      text-align: center;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
    }
  }

}