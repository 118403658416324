.desktop {
    min-width: 1150px;

    .text_container_1 h2 {
        color: #202330;
        font-family: Gilroy;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 55px;
        max-width: 418px;
    }

    .text_container_2 p {
        max-width: 400px;
        color: #202330;
        font-family: Gilroy;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
    }

    .start_up-text {
        padding: 100px;
    }
}

.mobile {
    .text_container_1 h2 {
        color: #202330;
        text-align: center;
        font-family: Gilroy;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        /* 100% */
    }

    .text_container_2 p {
        color: #202330;
        text-align: center;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        /* 138.889% */
    }

    .start_up-text {
        padding: 100px;
    }
}