.mobile {
    margin: auto;
    width: 50%;
    min-width: 250px;

    .steps_container {
        width: 100%;     
        padding-left: 20px;
        .step_cycle {
            width: 15px;
            height: 15px;
            margin: 20px;
            cursor: pointer;
        }

        .step_cycle:hover {
            background-color: #82a7f0;
            color: white;
        }
    }
}

.steps_container {
    display: flex;
    // align-items: center;
    position: relative;

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .step_cycle {
        width: 20px;
        height: 20px;
        background-color: white;
        color: #A3A5AE;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        font-weight: bold;
        margin-bottom: 10px;
        border: 2px solid #A3A5AE;
        margin-left: 50px;
        cursor: pointer;
    }

    .step_cycle:hover {
        background-color: #82a7f0;
        color: white;
    }

    .step_title {
        text-align: center;
        margin-left: 50px;
        color: #A3A5AE;
    }

    .step_content {
        width: 300x;
        text-align: center;
    }

    .line {
        height: 2px;
        background-color: #A3A5AE;
        position: absolute;
        top: 55%;
        left: 0;
        right: 50px;
        transform: translateY(-1px);
        /* Center the line vertically */
    }

    .selecte_item {
        .step_title {
            color: #0057FF;
            font-family: Gilroy;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .step_cycle {
            background-color: #0057FF;
        }
    }
}