.mobile {
    background-color: #F7F8FA;

    .values_title_cont {
        color: #202330;
        font-family: Gilroy;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        text-align: center;
    }

    .values_def_cont {
        color: #606370;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
    }

    .values_section {
        text-align: center;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }

        .values_icon {
            flex: 1;
            margin-top: 8px;
        }

        .values_icon img {
            width: 50px;
            height: 50px;
        }

        .values_text {
            flex: 4;
        }

        .values_text h3 {
            margin: 0;
            font-size: 20px;
            font-family: 'Gilroy', sans-serif;
            font-weight: 700;
            line-height: 28px;
            color: #606370;
        }

        .values_text p {
            font-size: 16px;
            font-family: 'Gilroy', sans-serif;
            font-weight: 500;
            line-height: 28px;
            color: #606370;
            margin-top: 4px;
        }
    }
}

.desktop {
    background-color: #F7F8FA;

    .values_title_cont {
        color: #202330;
        font-family: Gilroy;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
    }

    .values_def_cont {
        color: #606370;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        // width: 1100px;
    }

    .values_section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        margin-top: 35px;
    }

    .excellence_content,
    .innovation_content,
    .collaboration_content,
    .integrity_content,
    .professionalism_content {
        display: flex;
    }

    .values_icon {
        flex: 1;
        margin-top: 8px;
    }

    .values_icon img {
        width: 50px;
        height: 50px;
    }

    .values_text {
        flex: 4;
    }

    .values_text h3 {
        margin: 0;
        font-size: 20px;
        font-family: 'Gilroy', sans-serif;
        font-weight: 700;
        line-height: 28px;
        color: #606370;
    }

    .values_text p {
        font-size: 16px;
        font-family: 'Gilroy', sans-serif;
        font-weight: 500;
        line-height: 28px;
        color: #606370;
        margin-top: 4px;
    }
}