.mobile {

    text-align: center;

    .services_title {
        color: #7F828D;
        text-align: center;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 1.4px;
    }

    .title {
        color: #202330;
        text-align: center;
        font-family: Gilroy;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
    }

    .skill_image {
        img {
            max-width: 350px;
            width: 80%;
            min-width: 250px;
            height: 216px;
        }
    }

    .skill_description {
        h3 {
            color: #202330;
            text-align: center;
            font-family: Gilroy;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
        }

        p {
            color: #606370;
            text-align: center;
            font-family: Gilroy;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
        }
    }
}

.desktop {
    .services_title {
        color: #7F828D;
        font-family: Gilroy;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 1.5px
    }

    .title {
        color: #202330;
        font-family: Gilroy;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
    }

    .skill_image {
        padding: 10px;

        .skill_image_for_tab {
            width: 350px;
            height: 250px
        }

        img {
            width: 459px;
            height: 281px
        }
    }

    .skill_description {
        h3 {
            color: #202330;
            font-family: Gilroy;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
        }

        p {
            color: #606370;
            font-family: Gilroy;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            text-align: justify;
        }
    }
}