.mobile {
    .main_content {
        background: linear-gradient(180deg, #EFF5FF 0%, rgba(234, 241, 255, 0.00) 100%);
        text-align: center;

        .partner_title h3 {
            color: #7F828D;
            text-align: center;
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 35px;
            /* 250% */
            letter-spacing: 1.4px;
        }

        .title {
            color: #202330;
            text-align: center;
            font-family: Gilroy;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
        }

        .title_description {
            color: #606370;
            text-align: center;
            font-family: Gilroy;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
        }

        .companies {
            display: flex;
            padding-top: 50px;
            .company_item {
                background: none;
                width: 200px;
                height: 380px;
                padding: 10px;

                .company_image_wrapper {
                    text-align: center;
                    padding-top: 41px;
                    background-color: #FFF;

                    .company_image {
                        width: 195px;
                        height: 195px;
                    }
                }

            }

            h3 {
                color: #202330;

                text-align: center;
                font-family: Gilroy;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 50px;
            }
        }
    }
}




.desktop {
    // min-width: 1150px;

    .main_content {
        background: linear-gradient(180deg, #EFF5FF 0%, rgba(234, 241, 255, 0.00) 100%);

        .main_title h3 {
            color: #7F828D;
            font-family: Gilroy;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 35px;
            letter-spacing: 1.5px;
        }

        .title {
            color: #202330;
            font-family: Gilroy;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
            max-width: 341px;
            width: 341px;
        }

        .title_description {
            color: #606370;
            font-family: Gilroy;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }

        .companies {
            display: flex;
            padding-top: 50px;
            overflow-x: auto;
            overflow-y: hidden;

            .company_item {
                background: none;
                width: 280px;
                height: 380px;
                padding: 10px;

                .company_image_wrapper {
                    text-align: center;
                    padding-top: 41px;
                    background-color: #FFF;

                    .company_image {
                        width: 226px;
                        height: 202px;
                    }
                }

            }

            h3 {
                color: #202330;

                text-align: center;
                font-family: Gilroy;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 50px;
            }
        }
    }
}



.lapscreen {
    .content_item {
        div {
            width: 330px;
        }
    }
}