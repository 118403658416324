body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #0057FF rgb(243, 242, 240);
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: rgb(243, 242, 240);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #0057FF;
    border-radius: 20px;
    border: 3px solid rgb(243, 242, 240);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu {
  background-color: transparent;
  border-bottom: none;
}

.main_layout {
  .ant-layout-header {
    background-color: #fff;
    height: 10vh;
  }

  .ant-layout-content {
    position: relative;
    background-color: #fff;
    height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
    background-image: url("./Assests/background-img.svg");
    // background-position: top 155px right 0px;
  }

  .ant-layout-footer p {
    color: #b1b6be;
    margin: -15px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    font-family: Gilroy;
  }
}

.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 0;
}