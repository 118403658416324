.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;


}

.mobile_logo img {
  width: 70px;
  height: 30px;
  flex-shrink: 0;
}


.logo img {
  max-width: 116px;
  height: 50px;
  margin-top: 10px;
}

.nav_menu {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

.search_icon {
  font-size: 18px;
  cursor: pointer;
}

.lets_talk_btn {
  background-color: transparent;
  color: #1890ff;
  border-color: #1890ff;
  border-radius: 20px;
}

.lets_talk_btn:hover,
.lets_talk_btn:active {
  background-color: #40a9ff;
  border-color: #40a9ff;
  color: #fff;
}

.search_overlay {
  top: 70px;
  right: 70px;
  padding: 8px;
  display: flex;
  align-items: center;
}

.search_overlay input {
  flex: 1;
  margin-right: 10px;
  border-radius: 20px;
  height: 25px;
}

.close_icon {
  cursor: pointer;
}