  .section {
    padding: 10px;
    // display: flex;
    // justify-content: center;
  }

  .section.about {
    background-color: #f2faf8;
  }

  .section.service {
    background-color: #fff;
  }